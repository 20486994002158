import EN_COMMON from './en/common.json';
import EN_ASSORTMENT_ADMIN from './en/assortmentAdmin.json';
import EN_POINTS_TRANSACTION_ADMIN from './en/pointsTransactionAdmin.json';
import EN_ASSORTMENT_SELLER from './en/assortmentSeller.json';
import EN_PROMOTIONS_ADMIN from './en/promotionsAdmin.json';
import EN_REGISTRATION_SELLER from './en/registrationSeller.json';
import EN_REGISTRATIONS_ADMIN from './en/registrationsAdmin.json';
import EN_USERS from './en/users.json';
import EN_POS from './en/pos.json';
import EN_REPLACEMENT_SELLER from './en/replacementSeller.json';
import EN_REPLACEMENT_ADMIN from './en/replacementAdmin.json';
import EN_AUTH from './en/auth.json';
import EN_PROMOTIONS_SELLER from './en/promotionsSeller.json';
import EN_SUPPORT_SELLER from './en/supportSeller.json';
import EN_NEWS_ARTICLES_ADMIN from './en/newsArticlesAdmin.json';
import EN_NEWS_ARTICLES_SELLER from './en/newsArticlesSeller.json';
import EN_TASKS_ADMIN from './en/tasksAdmin.json';
import EN_MY_POINTS from './en/myPoints.json';
import EN_MY_PROFILE from './en/myProfile.json';
import EN_TASKS_SELLER from './en/tasksSeller.json';
import EN_VOUCHERS_ADMIN from './en/vouchersAdmin.json';
import EN_FOOTER from './en/footer.json';
import EN_GIFT_CODES from './en/giftCodeSeller.json';
import EN_GIFT_CODES_ADMIN from './en/giftCodeAdmin.json';
import EN_DASHBOARDS from './en/dashboards.json';
import EN_VERSION_CHECK from './en/versionCheck.json';

import HU_COMMON from './hu/common.json';
import HU_ASSORTMENT_ADMIN from './hu/assortmentAdmin.json';
import HU_POINTS_TRANSACTION_ADMIN from './hu/pointsTransactionAdmin.json';
import HU_ASSORTMENT_SELLER from './hu/assortmentSeller.json';
import HU_PROMOTIONS_ADMIN from './hu/promotionsAdmin.json';
import HU_REGISTRATION_SELLER from './hu/registrationSeller.json';
import HU_REGISTRATIONS_ADMIN from './hu/registrationsAdmin.json';
import HU_USERS from './hu/users.json';
import HU_POS from './hu/pos.json';
import HU_REPLACEMENT_SELLER from './hu/replacementSeller.json';
import HU_REPLACEMENT_ADMIN from './hu/replacementAdmin.json';
import HU_AUTH from './hu/auth.json';
import HU_PROMOTIONS_SELLER from './hu/promotionsSeller.json';
import HU_SUPPORT_SELLER from './hu/supportSeller.json';
import HU_NEWS_ARTICLES_ADMIN from './hu/newsArticlesAdmin.json';
import HU_NEWS_ARTICLES_SELLER from './hu/newsArticlesSeller.json';
import HU_TASKS_SELLER from './hu/tasksSeller.json';
import HU_TASKS_ADMIN from './hu/tasksAdmin.json';
import HU_MY_POINTS from './hu/myPoints.json';
import HU_MY_PROFILE from './hu/myProfile.json';
import HU_VOUCHERS_ADMIN from './hu/vouchersAdmin.json';
import HU_FOOTER from './hu/footer.json';
import HU_GIFT_CODES from './hu/giftCodeSeller.json';
import HU_PRIZE_WHEEL from './hu/prizeWheel.json';
import HU_DASHBOARDS from './hu/dashboards.json';
import HU_VERSION_CHECK from './hu/versionCheck.json';

import CZ_COMMON from './cz/common.json';
import CZ_ASSORTMENT_ADMIN from './cz/assortmentAdmin.json';
import CZ_POINTS_TRANSACTION_ADMIN from './cz/pointsTransactionAdmin.json';
import CZ_ASSORTMENT_SELLER from './cz/assortmentSeller.json';
import CZ_PROMOTIONS_ADMIN from './cz/promotionsAdmin.json';
import CZ_REGISTRATION_SELLER from './cz/registrationSeller.json';
import CZ_REGISTRATIONS_ADMIN from './cz/registrationsAdmin.json';
import CZ_USERS from './cz/users.json';
import CZ_POS from './cz/pos.json';
import CZ_REPLACEMENT_SELLER from './cz/replacementSeller.json';
import CZ_REPLACEMENT_ADMIN from './cz/replacementAdmin.json';
import CZ_AUTH from './cz/auth.json';
import CZ_PROMOTIONS_SELLER from './cz/promotionsSeller.json';
import CZ_SUPPORT_SELLER from './cz/supportSeller.json';
import CZ_NEWS_ARTICLES_ADMIN from './cz/newsArticlesAdmin.json';
import CZ_NEWS_ARTICLES_SELLER from './cz/newsArticlesSeller.json';
import CZ_TASKS_ADMIN from './cz/tasksAdmin.json';
import CZ_MY_POINTS from './cz/myPoints.json';
import CZ_MY_PROFILE from './cz/myProfile.json';
import CZ_TASKS_SELLER from './cz/tasksSeller.json';
import CZ_VOUCHERS_ADMIN from './cz/vouchersAdmin.json';
import CZ_FOOTER from './cz/footer.json';
import CZ_GIFT_CODES from './cz/giftCodeSeller.json';
import CZ_PRIZE_WHEEL from './cz/prizeWheel.json';
import CZ_DASHBOARDS from './cz/dashboards.json';
import CZ_VERSION_CHECK from './cz/versionCheck.json';

import PL_COMMON from './pl/common.json';
import PL_ASSORTMENT_ADMIN from './pl/assortmentAdmin.json';
import PL_POINTS_TRANSACTION_ADMIN from './pl/pointsTransactionAdmin.json';
import PL_ASSORTMENT_SELLER from './pl/assortmentSeller.json';
import PL_PROMOTIONS_ADMIN from './pl/promotionsAdmin.json';
import PL_REGISTRATION_SELLER from './pl/registrationSeller.json';
import PL_REGISTRATIONS_ADMIN from './pl/registrationsAdmin.json';
import PL_USERS from './pl/users.json';
import PL_POS from './pl/pos.json';
import PL_REPLACEMENT_SELLER from './pl/replacementSeller.json';
import PL_REPLACEMENT_ADMIN from './pl/replacementAdmin.json';
import PL_AUTH from './pl/auth.json';
import PL_PROMOTIONS_SELLER from './pl/promotionsSeller.json';
import PL_SUPPORT_SELLER from './pl/supportSeller.json';
import PL_NEWS_ARTICLES_ADMIN from './pl/newsArticlesAdmin.json';
import PL_NEWS_ARTICLES_SELLER from './pl/newsArticlesSeller.json';
import PL_TASKS_ADMIN from './pl/tasksAdmin.json';
import PL_MY_POINTS from './pl/myPoints.json';
import PL_MY_PROFILE from './pl/myProfile.json';
import PL_TASKS_SELLER from './pl/tasksSeller.json';
import PL_VOUCHERS_ADMIN from './pl/vouchersAdmin.json';
import PL_FOOTER from './pl/footer.json';
import PL_GIFT_CODES from './pl/giftCodeSeller.json';
import PL_DASHBOARDS from './pl/dashboards.json';
import PL_VERSION_CHECK from './pl/versionCheck.json';

export const resources = {
  en: {
    common: EN_COMMON,
    assortmentAdmin: EN_ASSORTMENT_ADMIN,
    pointsTransactionAdmin: EN_POINTS_TRANSACTION_ADMIN,
    assortmentSeller: EN_ASSORTMENT_SELLER,
    promotionsAdmin: EN_PROMOTIONS_ADMIN,
    registrationSeller: EN_REGISTRATION_SELLER,
    registrationsAdmin: EN_REGISTRATIONS_ADMIN,
    users: EN_USERS,
    pos: EN_POS,
    replacementSeller: EN_REPLACEMENT_SELLER,
    replacementAdmin: EN_REPLACEMENT_ADMIN,
    auth: EN_AUTH,
    promotionsSeller: EN_PROMOTIONS_SELLER,
    supportSeller: EN_SUPPORT_SELLER,
    newsArticlesAdmin: EN_NEWS_ARTICLES_ADMIN,
    newsArticlesSeller: EN_NEWS_ARTICLES_SELLER,
    tasksAdmin: EN_TASKS_ADMIN,
    myPoints: EN_MY_POINTS,
    myProfile: EN_MY_PROFILE,
    tasksSeller: EN_TASKS_SELLER,
    vouchersAdmin: EN_VOUCHERS_ADMIN,
    footer: EN_FOOTER,
    giftCodeSeller: EN_GIFT_CODES,
    giftCodeAdmin: EN_GIFT_CODES_ADMIN,
    dashboards: EN_DASHBOARDS,
    versionCheck: EN_VERSION_CHECK,
  },
  hu: {
    common: HU_COMMON,
    assortmentAdmin: HU_ASSORTMENT_ADMIN,
    pointsTransactionAdmin: HU_POINTS_TRANSACTION_ADMIN,
    assortmentSeller: HU_ASSORTMENT_SELLER,
    promotionsAdmin: HU_PROMOTIONS_ADMIN,
    registrationSeller: HU_REGISTRATION_SELLER,
    registrationsAdmin: HU_REGISTRATIONS_ADMIN,
    users: HU_USERS,
    pos: HU_POS,
    replacementSeller: HU_REPLACEMENT_SELLER,
    replacementAdmin: HU_REPLACEMENT_ADMIN,
    auth: HU_AUTH,
    promotionsSeller: HU_PROMOTIONS_SELLER,
    supportSeller: HU_SUPPORT_SELLER,
    newsArticlesAdmin: HU_NEWS_ARTICLES_ADMIN,
    newsArticlesSeller: HU_NEWS_ARTICLES_SELLER,
    tasksSeller: HU_TASKS_SELLER,
    tasksAdmin: HU_TASKS_ADMIN,
    myPoints: HU_MY_POINTS,
    myProfile: HU_MY_PROFILE,
    vouchersAdmin: HU_VOUCHERS_ADMIN,
    footer: HU_FOOTER,
    giftCodeSeller: HU_GIFT_CODES,
    prizeWheel: HU_PRIZE_WHEEL,
    dashboards: HU_DASHBOARDS,
    versionCheck: HU_VERSION_CHECK,
  },
  cz: {
    common: CZ_COMMON,
    assortmentAdmin: CZ_ASSORTMENT_ADMIN,
    pointsTransactionAdmin: CZ_POINTS_TRANSACTION_ADMIN,
    assortmentSeller: CZ_ASSORTMENT_SELLER,
    promotionsAdmin: CZ_PROMOTIONS_ADMIN,
    registrationSeller: CZ_REGISTRATION_SELLER,
    registrationsAdmin: CZ_REGISTRATIONS_ADMIN,
    users: CZ_USERS,
    pos: CZ_POS,
    replacementSeller: CZ_REPLACEMENT_SELLER,
    replacementAdmin: CZ_REPLACEMENT_ADMIN,
    auth: CZ_AUTH,
    promotionsSeller: CZ_PROMOTIONS_SELLER,
    supportSeller: CZ_SUPPORT_SELLER,
    newsArticlesAdmin: CZ_NEWS_ARTICLES_ADMIN,
    newsArticlesSeller: CZ_NEWS_ARTICLES_SELLER,
    tasksAdmin: CZ_TASKS_ADMIN,
    myPoints: CZ_MY_POINTS,
    myProfile: CZ_MY_PROFILE,
    tasksSeller: CZ_TASKS_SELLER,
    vouchersAdmin: CZ_VOUCHERS_ADMIN,
    footer: CZ_FOOTER,
    giftCodeSeller: CZ_GIFT_CODES,
    giftCodeAdmin: EN_GIFT_CODES_ADMIN,
    prizeWheel: CZ_PRIZE_WHEEL,
    dashboards: CZ_DASHBOARDS,
    versionCheck: CZ_VERSION_CHECK,
  },
  pl: {
    common: PL_COMMON,
    assortmentAdmin: PL_ASSORTMENT_ADMIN,
    pointsTransactionAdmin: PL_POINTS_TRANSACTION_ADMIN,
    assortmentSeller: PL_ASSORTMENT_SELLER,
    promotionsAdmin: PL_PROMOTIONS_ADMIN,
    registrationSeller: PL_REGISTRATION_SELLER,
    registrationsAdmin: PL_REGISTRATIONS_ADMIN,
    users: PL_USERS,
    pos: PL_POS,
    replacementSeller: PL_REPLACEMENT_SELLER,
    replacementAdmin: PL_REPLACEMENT_ADMIN,
    auth: PL_AUTH,
    promotionsSeller: PL_PROMOTIONS_SELLER,
    supportSeller: PL_SUPPORT_SELLER,
    newsArticlesAdmin: PL_NEWS_ARTICLES_ADMIN,
    newsArticlesSeller: PL_NEWS_ARTICLES_SELLER,
    tasksAdmin: PL_TASKS_ADMIN,
    myPoints: PL_MY_POINTS,
    myProfile: PL_MY_PROFILE,
    tasksSeller: PL_TASKS_SELLER,
    vouchersAdmin: PL_VOUCHERS_ADMIN,
    footer: PL_FOOTER,
    giftCodeSeller: PL_GIFT_CODES,
    dashboards: PL_DASHBOARDS,
    versionCheck: PL_VERSION_CHECK,
  },
};

import {
  GiftCodeFilterInput,
  GiftCodeFragment,
  GiftCodeSortInput,
  GiftCodeSortableFields,
  OrderBy,
  PaginationInput,
} from 'Apollo/graphql';
import {
  ChangeTableData,
  ChangeTableFilter,
  ChangeTableSort,
} from 'Utils/types';
import create from 'zustand';

export type TableGiftCodeFilter = {
  code: string;
  source: string;
  redeemedBy: string;
  customerPhoneNumber: string;
};

export type GiftCodeStore = {
  modalExportGiftCode: {
    isOpen: boolean;
  };
  tableGiftCodes: {
    data: {
      sortField: GiftCodeSortableFields | null;
      sortOrder: OrderBy | null;
      limit: number;
      offset: number;
    };
    filter: TableGiftCodeFilter;
  };
  openModalExportGiftCode: () => void;
  closeModalExporGiftCode: () => void;
  changeTableData: ChangeTableData;
  changeTableFilter: ChangeTableFilter;
  changeTableSort: ChangeTableSort;
};

export const useGiftCodeStore = create<GiftCodeStore>((set) => ({
  modalExportGiftCode: {
    isOpen: false,
  },
  tableGiftCodes: {
    data: {
      sortField: null,
      sortOrder: null,
      limit: 10,
      offset: 0,
    },
    filter: {
      code: '',
      source: '',
      redeemedBy: '',
      customerPhoneNumber: '',
    },
  },

  openModalExportGiftCode: () =>
    set({
      modalExportGiftCode: {
        isOpen: true,
      },
    }),
  closeModalExporGiftCode: () =>
    set(() => ({
      modalExportGiftCode: {
        isOpen: false,
      },
    })),

  /**
   * Gift code table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tableGiftCodes: {
        ...state.tableGiftCodes,
        data: {
          ...state.tableGiftCodes.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) =>
    set((state) => ({
      tableGiftCodes: {
        ...state.tableGiftCodes,
        data: {
          ...state.tableGiftCodes.data,
          offset: 0,
        },
        filter: {
          ...state.tableGiftCodes.filter,
          [name]: value,
        },
      },
    })),
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tableGiftCodes: {
        ...state.tableGiftCodes,
        data: {
          ...state.tableGiftCodes.data,
          sortField: sortField as GiftCodeSortableFields,
          sortOrder,
        },
      },
    })),
}));

export const mapVariablesGiftCodes = (
  storeData: GiftCodeStore['tableGiftCodes'],
): {
  filter?: GiftCodeFilterInput;
  pagination?: PaginationInput;
  sort?: GiftCodeSortInput;
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    code: storeData.filter.code
      ? { contains: storeData.filter.code }
      : undefined,
    source: storeData.filter.source
      ? { contains: storeData.filter.source }
      : undefined,
    redeemedBy: storeData.filter.redeemedBy
      ? { contains: storeData.filter.redeemedBy }
      : undefined,
    customerPhoneNumber: storeData.filter.customerPhoneNumber
      ? { contains: storeData.filter.customerPhoneNumber }
      : undefined,
  },
  sort:
    storeData.data.sortField && storeData.data.sortOrder
      ? {
          field: storeData.data.sortField,
          order: storeData.data.sortOrder,
        }
      : { field: GiftCodeSortableFields.CreatedAt, order: OrderBy.Desc },
});

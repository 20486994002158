import { useAppliedGiftCodesQuery } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import shallow from 'zustand/shallow';
import {
  GiftCodeStore,
  mapVariablesGiftCodes,
  useGiftCodeStore,
} from '../utils';
import AppliedCodesTableHeader from './AppliedCodesTableHeader';
import AppliedGiftCodesTableRow from './AppliedCodesTableRow';

const selectStoreData = (s: GiftCodeStore) => ({
  tableGiftCodes: s.tableGiftCodes,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const AppliedCodes = (): JSX.Element => {
  const { tableGiftCodes, changeFilter, changeSort, changeData } =
    useGiftCodeStore(selectStoreData, shallow);

  const { loading, error, data } = useAppliedGiftCodesQuery({
    variables: mapVariablesGiftCodes(tableGiftCodes),
    fetchPolicy: 'cache-and-network',
  });

  const colSpan = 13;

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={colSpan}
      rows={data?.appliedGiftCodes.data.length || 0}
      loading={loading}
      error={error}
      limit={tableGiftCodes.data.limit}
      sortField={tableGiftCodes.data.sortField}
      sortOrder={tableGiftCodes.data.sortOrder}
      offset={tableGiftCodes.data.offset}
      count={data?.appliedGiftCodes.pagination.count}
      filter={tableGiftCodes.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <AppliedCodesTableHeader />
      <TableBody>
        {(data?.appliedGiftCodes.data || []).map((row) => (
          <AppliedGiftCodesTableRow key={row.code} row={row} />
        ))}
      </TableBody>
    </Table>
  );
};

export default AppliedCodes;

import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import {
  GiftCodeStore,
  useGiftCodeStore,
} from 'Modules/GiftCodeAdmin/pages/GiftCodeAdminPage/utils';
import { useTranslation } from 'react-i18next';
import FetchData from './FetchData';

const selectModalData = (s: GiftCodeStore) => s.modalExportGiftCode;
const selectClose = (s: GiftCodeStore) => s.closeModalExporGiftCode;

const ModalExportGiftCodes = (): JSX.Element => {
  const { t } = useTranslation('giftCodeAdmin');
  const { isOpen } = useGiftCodeStore(selectModalData);
  const onClose = useGiftCodeStore(selectClose);

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>
        {t(`common:defaultExport.title`, { name: t('giftCodes.title') })}
      </ModalDrawerTitle>
      <FetchData onClose={onClose} />
    </ModalDrawer>
  );
};

export default ModalExportGiftCodes;

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Claim, CustomerSortableFields } from 'Apollo/graphql';
import TableCellSortable from 'Components/Table/TableCellSortable';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import { useAuthUser } from 'Tools/auth';
import { useTranslation } from 'react-i18next';

export const COL_WIDTHS = [
  { width: '50px' },
  { width: '100px' },
  { width: '130px' },
  { width: '150px' },
  { width: '200px' },
  { width: '250px' },
];

const AppliedGiftCodesTableHeader = (): JSX.Element => {
  const { t } = useTranslation('giftCodeAdmin');
  const authUser = useAuthUser();
  const showSensitiveData = authUser?.claimsSet.has(Claim.ReadSensitiveData);

  return (
    <TableHead>
      <TableRow>
        <TableCellSortable
          style={COL_WIDTHS[3]}
          name={CustomerSortableFields.CreatedAt}
        >
          {t('giftCodes.table.code')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[4]}>
          {t('giftCodes.table.source')}
        </TableCell>
        <TableCell style={COL_WIDTHS[5]}>
          {t('giftCodes.table.appliedBy')}
        </TableCell>
        {showSensitiveData && (
          <TableCell style={COL_WIDTHS[5]}>
            {t('giftCodes.table.phone')}
          </TableCell>
        )}
        <TableCell style={COL_WIDTHS[3]}>
          {t('giftCodes.table.appliedAt')}
        </TableCell>
        <TableCell style={COL_WIDTHS[3]}>
          {t('giftCodes.table.appliedOn')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText name="code" />
        </TableCell>
        <TableCell>
          <TableFilterText name="source" />
        </TableCell>
        <TableCell>
          <TableFilterText name="redeemedBy" />
        </TableCell>
        {showSensitiveData && (
          <TableCell>
            <TableFilterText name="customerPhoneNumber" />
          </TableCell>
        )}
        <TableCell>
          <TableFilterText name="posId" />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default AppliedGiftCodesTableHeader;

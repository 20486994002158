import TableRow from '@mui/material/TableRow';
import { Claim, GiftCodeFragment } from 'Apollo/graphql';
import TableCellText from 'Components/Table/TableCellText';
import { useAuthUser } from 'Tools/auth';
import { useTranslation } from 'react-i18next';

interface Props {
  row: GiftCodeFragment;
}

const AppliedGiftCodeTableRow = ({ row }: Props): JSX.Element => {
  const { t } = useTranslation('giftCodeAdmin');
  const authUser = useAuthUser();
  const showSensitiveData = authUser?.claimsSet.has(Claim.ReadSensitiveData);

  return (
    <TableRow key={row.code}>
      <TableCellText>{row.code}</TableCellText>
      <TableCellText>{row.customer?.registrationSourceId}</TableCellText>
      <TableCellText>{row.redeemedBy?.username}</TableCellText>
      {showSensitiveData && (
        <TableCellText>{row.customer?.phoneNumberWithPrefix}</TableCellText>
      )}
      <TableCellText>{row.customer?.posId}</TableCellText>
      <TableCellText>
        {t('common:date.fullDate', { date: new Date(row.customer?.createdAt) })}
      </TableCellText>
    </TableRow>
  );
};

export default AppliedGiftCodeTableRow;

import { useState } from 'react';
import shallow from 'zustand/shallow';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import AppContainer from 'Components/AppContainer';
import {
  RegistrationStore,
  useRegistrationStore,
} from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import {
  PurchasesStore,
  usePurchasesStore,
} from 'Modules/RegistrationsAdmin/store/PurchasesStore';
import ModalRemoveRegistration from 'Modules/RegistrationsAdmin/modals/ModalRemoveRegistration';
import ModalDetailRegistration from 'Modules/RegistrationsAdmin/modals/ModalDetailRegistration';
import ModalEraseAllCustomerData from 'Modules/RegistrationsAdmin/modals/ModalEraseAllCustomerData';
import ModalDetailPurchases from 'Modules/RegistrationsAdmin/modals/ModalDetailPurchases';
import ModalQrCode from 'Modules/RegistrationsAdmin/modals/ModalQrCode';
import { useAuthUser } from 'Tools/auth';
import CopyIcon from 'Utils/svg/CopyIcon';
import ModalExportRegistrations from 'Modules/RegistrationsAdmin/modals/ModalExportCustomers';
import ModalExportPurchases from 'Modules/RegistrationsAdmin/modals/ModalExportPurchases';
import { Claim } from 'Apollo/graphql';
import AppliedCodes from './AppliedCodesTable/AppliedCodes';
import { GiftCodeStore, useGiftCodeStore } from './utils';
import ModalExportGiftCodes from 'Modules/GiftCodeAdmin/modals/ModalExportGiftCode';

const selectStoreData = (s: GiftCodeStore) => ({
  exportModal: s.openModalExportGiftCode,
});

const GiftCodeAdminPage = (): JSX.Element => {
  const { t } = useTranslation('giftCodeAdmin');
  const authUser = useAuthUser();
  const { exportModal } = useGiftCodeStore(selectStoreData, shallow);

  return (
    <>
      <AppContainer>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          paddingBottom={2}
        >
          <Grid item>
            <Typography variant="h1">{t('giftCodes.title')}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {authUser?.claimsSet.has(Claim.AdminExportData) && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="large"
                    onClick={exportModal}
                  >
                    {t('giftCodes.btnExport')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Tabs value={0}>
          <Tab label={t('giftCodes.applied.title')} />
        </Tabs>
        <AppliedCodes />
      </AppContainer>
      <ModalExportGiftCodes />
    </>
  );
};

export default GiftCodeAdminPage;
